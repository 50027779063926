import React from 'react';

const LandingPage = () => {
  return (
    <>
      <header>
        <nav id='nav' class='nav-bar'>
          <div class='nav-container'>
            <div class='header-top'>
              <h3 class='vaporwave-header-text'>VAPORWAVE</h3>
            </div>
            <div class='header-bottom'></div>
            <ul class='nav-ul'>
              <li class='nav-item'>
                <a class='nav-link active' aria-current='page' href='#nav'>
                  HOME
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#contact'>
                  LOCATION
                </a>
              </li>
              <li class='nav-item'>
                <a class='nav-link' href='#contact'>
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <div
        id='carouselIndicators'
        class='carousel slide'
        data-bs-ride='carousel'
        data-bs-interval='5000'
        data-bs-touch='true'
      >
        {/* <div
        id='carouselIndicators'
        class='carousel slide'
        data-bs-interval='5000'
        data-bs-touch='true'
      > */}
        <div class='carousel-indicators'>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='0'
            class='active'
            aria-current='true'
            aria-label='Slide 1'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='1'
            aria-label='Slide 2'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='2'
            aria-label='Slide 3'
          ></button>
          <button
            type='button'
            data-bs-target='#carouselIndicators'
            data-bs-slide-to='3'
            aria-label='Slide 4'
          ></button>
        </div>
        <div class='carousel-inner categories'>
          <div class='carousel-item active category'>
            <div class='category-name-container'>
              <h3 class='category-name'>SMOKE SHOP</h3>
            </div>

            <div class='img-container'>
              <img src='/images/smokeshop1.jpg' class='d-block' alt='...' />
            </div>
            <div class='category-desc'>
              <p>
                WATERPIPES, HAND PIPES, CART BATTERIES, E-DAB RIGS, BANGERS,
                WRAPS, AND ALL OF YOUR PARAPHERNALIA NEEDS!
              </p>
            </div>
          </div>
          <div class='carousel-item category'>
            <div class='category-name-container'>
              <h3 class='category-name'>VAPE SHOP</h3>
            </div>
            <div class='img-container'>
              <img src='/images/vapeshop1.jpg' class='d-block' alt='...' />
            </div>
            <div class='category-desc'>
              <p>
                DISPOSABLES, STARTER KITS, E-LIQUID, COILS & PODS AND MORE OF
                YOUR VAPE SHOP NEEDS!
              </p>
            </div>
          </div>
          <div class='carousel-item category'>
            <div class='category-name-container'>
              <h3 class='category-name'>3D PRINTING</h3>
            </div>
            <div class='img-container'>
              <img src='/images/3dprints1.jpg' class='d-block' alt='...' />{' '}
            </div>
            <div class='category-desc'>
              <p>
                DISPOSABLES, STARTER KITS, E-LIQUID, COILS & PODS AND MORE OF
                YOUR VAPE SHOP NEEDS!
              </p>
            </div>
          </div>
          <div class='carousel-item category'>
            <div class='category-name-container'>
              <h3 class='category-name'>STICKERS</h3>
            </div>
            <div class='img-container'>
              <img src='/images/stickers1.jpg' class='d-block' alt='...' />
            </div>
            <div class='category-desc'>
              <p>
                LARGE AMOUNT OF ALL KINDS OF STICKERS. NEW STICKERS DELIVERED
                WEEKLY!
              </p>
            </div>
          </div>
        </div>
        <button
          class='carousel-control-prev'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='prev'
        >
          <span class='carousel-control-prev-icon' aria-hidden='true'></span>
          <span class='visually-hidden'>Previous</span>
        </button>
        <button
          class='carousel-control-next'
          type='button'
          data-bs-target='#carouselIndicators'
          data-bs-slide='next'
        >
          <span class='carousel-control-next-icon' aria-hidden='true'></span>
          <span class='visually-hidden'>Next</span>
        </button>
      </div>
      <footer>
        <div class='bottom-container' id='contact'>
          <div class='logo-footer-container'>
            <div class='logo-footer'>
              <h2 class='logo-footer-vw'>VAPORWAVE</h2>
              <h3 class='logo-footer-ss'>SUN CITY</h3>
            </div>
          </div>

          <div class='business-info'>
            <div class='biz-info-offer'>
              <i class='fa-solid fa-handshake biz-info-i'></i>
              <div class='biz-info-title'>WHAT WE OFFER</div>
              <div class='biz-info-text'>
                VAPE SUPPLIES
                <br />
                PARAPHERNALIA
                <br />
                3D PRINTS
                <br />
                STICKERS
                <br />
              </div>
            </div>
            <div class='biz-contact'>
              <i class='fa-sharp fa-solid fa-location-dot biz-info-i'></i>
              <div class='biz-info-title'>CONTACT</div>
              <div class='biz-info-text'>
                <a href='tel:623-933-3852' class='contact-number biz-info-text'>
                  (623) 933-3852
                </a>
                <a
                  href='https://goo.gl/maps/R11zchMGZi6eRKbu6'
                  target='_blank'
                  class='biz-address biz-info-text'
                  rel='noreferrer'
                >
                  10001 W BELL RD #110
                  <br />
                  SUN CITY, AZ 85351
                </a>
                <a
                  href='mailto: tracy@vaporwavesuncity.com'
                  class='biz-contact-email biz-info-text'
                >
                  TRACY@VAPORWAVESUNCITY.COM
                </a>
              </div>
            </div>
            <div class='biz-hours'>
              <i class='fa-solid fa-business-time biz-info-i'></i>
              <div class='biz-info-title'>STORE HOURS</div>
              <div class='biz-info-text'>
                MON-SAT 10AM-8PM
                <br />
                SUNDAY CLOSED
              </div>
            </div>
          </div>
          <div class='social-media-container'>
            <h4 class='social-media-title'>FOLLOW US</h4>

            <div class='social-media'>
              <a
                href='https://www.facebook.com/VaporwaveSunCity/'
                target='_blank'
              >
                <i class='fa-brands fa-facebook-f social-i'></i>
              </a>
              <a
                href='https://www.instagram.com/vaporwavesuncity/'
                target='_blank'
              >
                <i class='fa-brands fa-instagram social-i'></i>
              </a>
              <a href='https://twitter.com/vaporwaveaz' target='_blank'>
                <i class='fa-brands fa-twitter social-i'></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
